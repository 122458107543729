export const EXPERIENCE_TYPE = "nvs";

export enum PAGE_TYPE {
  landing = "landing",
  onboarding = "onboarding",
  profile = "profile",
  cdp = "cdp",
  globalNav = "global-nav",
  tlp = "tlp",
  pdp = "pdp",
  checkout = "checkout",
  reveal = "reveal",
  settings = "settings",
  clp = "clp",
  gallery = "gallery",
  achievements = "achievements",
  orderHistory = "order-history",
  glitchGalaxy = "glitch-galaxy",
  marketing = "marketing",
  marketing3DModel = "marketing 3d model",
  lowPoly = "low-poly",
  tokenTransfer = "token-transfer",
  gamePartner = "game-partner-page",
}

export enum CLASSIFICATION_TYPE {
  coreBuyFlow = "core-buy-flow",
  experienceEvent = "experience-event",
}

export enum EVENT_TYPE {
  page = "page",
  track = "track",
}

export enum INITIATIVE {
  preOrder = "Pre-Order",
}
